import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./LoanRequest.module.scss";
import CONFIG from "../../../../helpers/config";
import Okra from "okra-js";
import MonoConnect from "@mono.co/connect.js";
import { postData, putData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { Alert } from "antd";
import { decrypt } from "../../../../helpers/encryptor";
import { ISuccess } from "../../../../interfaces/success";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import {
  updateMonoWidgetDataAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

type BankStatementErrorScreenProps = {
  onNextStep: () => void;
  handleCancel: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  userExistsData: any;
  isLoadingUserExist: any;
  bankStatementLinkedStatus: any;
  isLoadingBankStatementLinkedStatus: boolean;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<ISuccess>>;
  error: string;
  success: ISuccess;
};

function BankStatementErrorScreen({
  onNextStep,
  setCurrentStep,
  userExistsData,
  isLoadingUserExist,
  bankStatementLinkedStatus,
  isLoadingBankStatementLinkedStatus,
  setError,
  setSuccess,
  error,
  success,
}: BankStatementErrorScreenProps) {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const loanStateData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (BankStatementLinkErrorScreen.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: BankStatementLinkErrorScreen.tsx`, {
      page: "BankStatementLinkErrorScreen.tsx - (Web)",
    });
  }, []);

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }

    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = React.useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }

    const customer = {
      name: userInfo?.first_name + " " + userInfo?.last_name,
      email: userInfo?.email,
    };

    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log("closed");
      },
      onLoad: () => console.log("Loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          console.log("Widget is open!");
          dispatch(
            updateMonoWidgetDataAction({
              opened: true,
              opened_dateTimeStamp: data?.timestamp,
              customer_email: userInfo?.email,
            }),
          );
        } else if (eventName === "INSTITUTION_SELECTED") {
          dispatch(
            updateMonoWidgetDataAction({
              is_institution_selected: true,
              selected_institution_name: data.institution.name,
              institution_selected_dateTimeStamp: data?.timestamp,
            }),
          );
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
    //eslint-disable-next-line
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    setError("");
    setSuccess((prevState) => ({ ...prevState, bodyText: "" }));
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: userInfo?.customerId,
      aggregator_id: userInfo?.aggregatorId,
    };
    try {
      dispatch(updateWebSettingsAction({ isSavingProvider: true }));
      await putData(apiEndpoints.saveBankstatementProviderWeb, reqBody);
      setSuccess((prevState) => ({
        ...prevState,
        bodyText: "Bank provider details saved successfully",
      }));
      handleCreateLoan();
    } catch (error) {
      setError("Something went wrong please try again");
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (BankStatementErrorScreen.tsx)",
        },
      });
    } finally {
      dispatch(updateWebSettingsAction({ isSavingProvider: false }));
    }
  };

  const handleBankStatementOrCreateLoan = () => {
    dispatch(
      updateMonoWidgetDataAction({
        opened: false,
        opened_dateTimeStamp: null,
        customer_email: null,
        is_institution_selected: false,
        selected_institution_name: null,
        institution_selected_dateTimeStamp: null,
      }),
    );
    if (!isLoadingUserExist && !isLoadingBankStatementLinkedStatus) {
      if (!bankStatementLinkedStatus?.status && handleLaunchOkraWidget) {
        if (userExistsData?.bank_statement_provider?.toLowerCase() === "okra") {
          handleLaunchOkraWidget();
        } else {
          //@ts-ignore
          monoConnect.open();
        }
      }
    }
  };

  async function handleCreateLoan() {
    setError("");
    setSuccess((prevState) => ({
      ...prevState,
      headerText: "",
      bodyText: "",
    }));
    setCurrentStep(4);
    try {
      const res = await postData(apiEndpoints.customerProcessCustomerLoan, {
        loan_amount: loanStateData?.loan_amount,
        loan_name: loanStateData?.loan_name,
        loan_tenure: loanStateData?.loan_tenure,
        product_id: loanStateData?.product_id,
        repayment_start_date: loanStateData?.repayment_start_date,
        bvn_checker: userInfo?.isBvnVerified,
        annual_interest_rate: loanStateData?.annual_interest_rate,
        aggregator_loan_ref: Date.now().toString(),
        bvn_number: decrypt(userInfo?.bvn),
        business_registration_number:
          loanStateData?.business_registration_number,
        phone_number: userInfo?.phoneNumber,
        country_code: userInfo?.country,
        aggregator_id: userInfo?.aggregatorId,
        customer_bank_account: loanStateData?.customer_bank_account,
        is_bankstatement_linkage_failed: false,
        loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
        total_cost_amount: loanStateData?.total_cost_amount,
        equity_contribution_amount: loanStateData?.equity_contribution_amount,
        description: loanStateData?.description,
        bank_statement_provider_response: JSON.stringify({
          opened: monoWidgetData.opened,
          customer_email: monoWidgetData.customer_email,
          opened_dateTimeStamp: monoWidgetData.opened_dateTimeStamp,
          is_institution_selected: monoWidgetData.is_institution_selected,
          selected_institution_name: monoWidgetData.selected_institution_name,
          institution_selected_dateTimeStamp:
            monoWidgetData.institution_selected_dateTimeStamp,
        }),
      });
      setSuccess((prevState) => ({
        ...prevState,
        headerText: "Request Submitted Successfully",
        bodyText: res.message,
      }));
      MixPanel.track("Get Loan", {
        distinct_id: `${userInfo?.email}`,
        "loan amount": `${loanStateData?.loan_amount}`,
        "loan name": `${loanStateData?.loan_name}`,
        "loan tenor": `${loanStateData?.loan_tenure}`,
        "product name": `${loanStateData?.product_name}`,
        "Loan status": `Application success`,
        "bank statement linked status": `linked`,
      });
      setCurrentStep(5);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (BankStatementErrorScreen.tsx)",
        },
      });
      setCurrentStep(6);
    }
  }
  return (
    <div className={styles.stepNine_container}>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success.bodyText && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={success.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              setSuccess({
                ...success,
                bodyText: "",
              })
            }
          />
        </div>
      )}
      <div className={styles.verification_failed}>
        <div className={styles.box}></div>
        <h3>Something went wrong</h3>
        <p>
          We couldn't get your bank statement at the moment. You can try adding
          a different account
        </p>
      </div>

      <div className={styles.user_options}>
        <button onClick={handleBankStatementOrCreateLoan}>Try again</button>
        <button onClick={onNextStep}>Continue</button>
      </div>
    </div>
  );
}

export default BankStatementErrorScreen;
