import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import styles from "./GettingStarted.module.scss";
import { Link } from "react-router-dom";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import useFetchCountries from "../../../../custom-hooks/useFetchCountries";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { getData, postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import Loader from "../../../../components/Loader/Loader";
import useOtpTypes from "../../../../custom-hooks/useOtpTypes";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateBorrowerDetailsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

interface CountryCode {
  country_code?: string;
  currency?: string;
  dial_code?: string;
  id?: number;
  is_active?: number;
  name?: string;
}

interface FormData {
  email: string;
  country: string;
  phoneNumber: string;
  bvn: string;
  nationalID: string;
}

interface BVNResponse {
  data: {
    first_name: string | null;
    last_name: string | null;
    dob: string | null;
    formatted_dob: string | null;
    mobile: string | null;
    bvn: string | null;
    gender: string | null;
    residential_address: string | null;
  };
}

const nigerianSchema = yup.object().shape({
  email: yupValidators.email,
  country: yupValidators.country,
  phoneNumber: yupValidators.phone_number,
  bvn: yupValidators.bvn,
});

const otherCountriesSchema = yup.object().shape({
  email: yupValidators.email,
  country: yupValidators.country,
  phoneNumber: yupValidators.phone_number,
  nationalId: yupValidators.nationalId,
});

interface GettingStartedProp {
  onNextStep: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

enum ProductDomainEnum {
  Spark = 1,
  Advest = 2,
  EmbedLite = 3,
}

function GettingStarted({ onNextStep, error, setError }: GettingStartedProp) {
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );
  const [searchedResults, setSearchedResults] = useState<CountryCode[]>([]);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    country_code: "ng",
    dial_code: "234",
  });
  const [loading, setLoading] = useState(false);
  const [isValidatingAggregator, setIsValidatingAggregator] = useState(false);
  const [aggregatorInfo, setAggregatorInfo] = useState<any>(null);
  const [verifyingID, setVerifyingID] = useState(false);
  const { courseAmount, courseDescription, partnerName } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      selectedCountry.country_code === "ng"
        ? nigerianSchema
        : otherCountriesSchema,
    ),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Onboarding  - (GettingStarted.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: GettingStarted.tsx`, {
      page: "GettingStarted.tsx - (Web Onboarding)",
    });
  }, []);

  const selectedCountryCode = watch("country");

  const { data: countryLists, isLoading: isLoadingCountries } =
    useFetchCountries();

  async function validateAggregator(shortName: string) {
    setIsValidatingAggregator(true);

    try {
      const response = await getData(
        `${apiEndpoints.validateAggregator}?shortName=${shortName}`,
      );

      if (response.status) {
        setAggregatorInfo(response.data);
      } else {
        setIsValidatingAggregator(false);
        history.push(`/${partnerName}/page-not-found`);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Onboarding - (GettingStarted.tsx)",
        },
      });
      history.push(`/${partnerName}/page-not-found`);
    } finally {
      setIsValidatingAggregator(false);
    }
  }

  useEffect(() => {
    validateAggregator(partnerName);
  }, [partnerName]);

  useEffect(() => {
    if (selectedCountryCode) {
      const foundCountry = countryLists?.find(
        (country: CountryCode) => country.country_code === selectedCountryCode,
      );
      if (foundCountry) {
        handleCountrySelection(foundCountry);
      }

      dispatch(
        updateBorrowerDetailsAction({
          phoneNoCountryDialCode: String(foundCountry?.dial_code),
        }),
      );
    }
  }, [countryLists, selectedCountryCode]);

  const filterPrompts = (searchText: string) => {
    const regex = new RegExp(searchText, "i");

    return countryLists.filter((country: CountryCode) => {
      const name = country.name || "";
      const countryCode = country.country_code || "";

      return regex.test(name) || regex.test(countryCode);
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout as NodeJS.Timeout);
    setSearchText(e.target.value);

    //debounce method
    setSearchTimeout(
      setTimeout(() => {
        const searchResult = filterPrompts(e.target.value);
        setSearchedResults(searchResult);
      }, 500),
    );
  };

  const handleCountryDisplay = () => {
    setDisplayCountry((prev) => !prev);
  };

  const handleCountrySelection = (country: CountryCode) => {
    setSelectedCountry({
      country_code: country?.country_code?.toLowerCase(),
      dial_code: String(country.dial_code),
    });
    setDisplayCountry(false);
  };

  const { data: otpTypes } = useOtpTypes();

  const renderCountryList = () => {
    return countryLists?.map((country: CountryCode) => (
      <option key={country?.id} value={country?.country_code}>
        {country?.name}
      </option>
    ));
  };

  const onSubmit = async ({
    email,
    phoneNumber,
    country,
    bvn,
    nationalID,
  }: FormData) => {
    setError("");
    if (!selectedCountry.dial_code) {
      setError("Invalid dial code!");
      return;
    }
    setLoading(true);
    let response;
    // console.log("Inside:", selectedCountry.dial_code)

    const idType = bvn ? "BVN" : "NATIONAL ID";
    response = await resolveID({
      countryCode: country,
      identificationNumber: idType === "BVN" ? bvn : nationalID,
      bvn: idType === "BVN" ? bvn : undefined,
      aggregatorId: aggregatorInfo?.id,
      emailAddress: email,
      phoneNumber: phoneNumber,
      productDomain: ProductDomainEnum.EmbedLite,
    });
    setLoading(false);

    if (!response) return;
    try {
      const reqBody = {
        email,
        phoneNumber,
        partner: aggregatorInfo?.bizName,
        bvn,
        productDomain: "EmbedLite",
        countryCode: country,
        otpType: otpTypes?.find(
          (o: { name: string }) => o?.name === "Onboarding",
        )?.id,
      };

      await postData(apiEndpoints.validate_sign_up_email, reqBody);

      dispatch(
        updateBorrowerDetailsAction({
          first_name: response?.first_name || "",
          last_name: response?.last_name || "",
          dob: response?.dob || "",
          isBvnValidated: response?.isBvnValidated || false,
          email,
          borrower_phone: phoneNumber,
          isEmailVerified: false,
          phoneNoCountryDialCode: selectedCountry.dial_code,
          bvn_number: bvn,
          gender: response.gender || "",
          country_code: country,
          aggregator_id: aggregatorInfo?.id,
          aggregator_name: aggregatorInfo?.shortName,
        }),
      );

      onNextStep();
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);

      MixPanel.track("Sign Up", {
        distinct_id: `${email}`,
        URL: window.location.href,
        product: "Embed Web",
        status: `Failed: ${error.response?.data?.message ?? error.message}`,
        step: "GettingStarted.tsx",
      });
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Onboarding - (GettingStarted.tsx)",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const resolveID = async (reqBody: {
    countryCode: string;
    identificationNumber: string;
    bvn?: string;
    aggregatorId: number;
    emailAddress: string;
    phoneNumber: string;
    productDomain: number;
  }) => {
    setError("");
    let bvn: string | null = null;
    setVerifyingID(true);
    try {
      const {
        data: {
          formatted_dob,
          first_name,
          last_name,
          gender,
          bvn: responseDataBvn,
        },
      }: BVNResponse = await postData(`${apiEndpoints.resolve_BVN}`, reqBody);
      bvn = responseDataBvn;
      return {
        first_name: String(first_name),
        last_name: String(last_name),
        dob: String(formatted_dob),
        isBvnValidated: true,
        bvn: String(bvn),
        gender: String(gender),
      };
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);

      dispatch(
        updateBorrowerDetailsAction({
          isBvnValidated: false,
        }),
      );

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Onboarding - (GettingStarted.tsx)",
        },
      });
    } finally {
      setVerifyingID(false);
    }
  };

  return (
    <>
      {isValidatingAggregator ? (
        <div className={styles.validate_aggregator}>
          <span className={`spinner-border spinner-border-lg mr-2 `}></span>
          <span className={styles.validating_text}>
            Please wait, we are validating this aggregator...
          </span>
        </div>
      ) : (
        <div className={styles.stepOne_container}>
          {error && (
            <div style={{ width: "100%", paddingTop: "60px" }}>
              <Alert
                message={error}
                type="error"
                showIcon
                closable
                onClose={() => setError("")}
              />
            </div>
          )}
          <div>
            <header className={styles.stepOne_header}>
              <h1>Let’s get started</h1>
              <p>Enter the following information to begin</p>
            </header>
            <form
              className={styles.stepOne_form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomSelectDropdown
                defaultValue="Select country"
                label="Country"
                extraLabel={isLoadingCountries && "Loading countries..."}
                name="country"
                reference={register}
                errors={errors.country}
              >
                <option value="">Select country</option>
                {renderCountryList()}
              </CustomSelectDropdown>

              {selectedCountryCode && (
                <div className={styles.input_container}>
                  <div className={styles.input_label_container}>
                    <label className={styles.email_label}>
                      Email address{" "}
                      <span style={{ color: "#713fff" }}>
                        (Enter your registered email address with{" "}
                        {aggregatorInfo?.shortName})
                      </span>
                    </label>
                    <input
                      type="email"
                      placeholder="example@gmail.com"
                      name="email"
                      ref={register}
                    />
                  </div>
                  <span className={styles.email_error}>
                    {errors.email?.message}
                  </span>
                </div>
              )}

              {selectedCountryCode && (
                <div className={styles.verify_phone}>
                  <label htmlFor="phone_number" className={styles.phone_label}>
                    Phone Number
                  </label>
                  <div className={styles.selected_option}>
                    <div className={styles.flag} onClick={handleCountryDisplay}>
                      <Icon
                        icon={`flag:${selectedCountry.country_code?.toLowerCase()}-4x3`}
                        className={styles.iconify}
                      />
                      <span>{selectedCountry.dial_code}</span>
                      {displayCountry ? (
                        <IoIosArrowUp className={styles.arrow} />
                      ) : (
                        <IoIosArrowDown className={styles.arrow} />
                      )}
                    </div>
                    <input
                      type="tel"
                      name="phoneNumber"
                      placeholder="07000000000"
                      ref={register}
                    />
                  </div>
                  {displayCountry && (
                    <div className={styles.options}>
                      <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchChange}
                        className={styles.search_box}
                        placeholder="Search Country Name"
                      />
                      <ol>
                        {searchText
                          ? searchedResults.map((country) => (
                              <li
                                key={country.name}
                                onClick={() => handleCountrySelection(country)}
                              >
                                <div>
                                  <Icon
                                    icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                                    className={styles.iconify}
                                  />
                                  <span>{country.name}</span>
                                </div>
                                <span>{country.dial_code}</span>
                              </li>
                            ))
                          : countryLists.map((country: CountryCode) => (
                              <li
                                key={country.name}
                                onClick={() => handleCountrySelection(country)}
                              >
                                <div>
                                  <Icon
                                    icon={`flag:${country?.country_code?.toLowerCase()}-4x3`}
                                    className={styles.iconify}
                                  />
                                  <span>{country.name}</span>
                                </div>
                                <span>{country.dial_code}</span>
                              </li>
                            ))}
                      </ol>
                    </div>
                  )}
                  <span className={styles.phoneNumberValidation_error}>
                    {errors.phoneNumber?.message}
                  </span>
                </div>
              )}

              {!selectedCountryCode ? null : selectedCountryCode === "NG" ? (
                <div>
                  <CustomInputField
                    type="text"
                    label="BVN"
                    placeholder="Enter BVN"
                    name="bvn"
                    reference={register}
                    maxLength={11}
                    errors={errors.bvn}
                  />
                  {verifyingID && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        position: "absolute",
                      }}
                    >
                      <div style={{ marginTop: "3px" }}>
                        <Loader />
                      </div>
                      <span className={styles.id_status}>Verifying BVN...</span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <CustomInputField
                    type="text"
                    label="National ID"
                    placeholder="Enter your national ID number"
                    name="nationalId"
                    reference={register}
                    maxLength={8}
                    errors={errors.nationalId}
                  />
                  {verifyingID && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        position: "absolute",
                      }}
                    >
                      <div style={{ marginTop: "3px" }}>
                        <Loader />
                      </div>
                      <span className={styles.id_status}>Verifying ID...</span>
                    </div>
                  )}
                </div>
              )}
              <div className={styles.submit__section}>
                <div className={styles.submit__btn}>
                  <button type="submit" disabled={loading}>
                    Proceed
                  </button>
                </div>
                <p>
                  Do you have an account?{" "}
                  <Link
                    to={`/${aggregatorInfo?.shortName}/login`}
                    className={styles.login}
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </form>

            <aside className={styles.aside}>
              By proceeding to the next step, I acknowledge that I have read and
              agree to the terms and conditions outlined in Advancly's{" "}
              <a
                href="https://advancly.com/terms-conditions/"
                rel="noreferrer"
                className="pl-1 pr-1 color-blue"
              >
                Terms of Use
              </a>
              and
              <a
                href="https://advancly.com/privacy-policy/"
                rel="noreferrer"
                className="pl-1 color-blue"
              >
                Privacy Policy.
              </a>
            </aside>
          </div>
        </div>
      )}
    </>
  );
}

export default GettingStarted;
